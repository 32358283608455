<script>

import Pagination from '@/components/common/Pagination/Pagination'
import PlaneCheckbox from '@/components/common/Checkbox/PlaneCheckbox.vue';
import FlyerShortInfoPopup from '@/components/common/modals/FlyerShortInfoPopup.vue';

export default {
  props: ['pageCount', 'rowCount', 'dataList'],
  name: 'UsersSubscriptionsTable',
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    flyerInfo: {},
  }),
  methods: {
    onPageClick (data) {
      this.$emit('pageClick', data);
    },
    getDisplayDate (dateString) {
      return dateString === null
        ? ''
        : `${new Date(dateString).toLocaleDateString('en-US')}`;
    },
    getDisplayDateTime (dateString) {
      const options = {timeZone: 'America/New_York', hour12: false};
      let date = new Date(dateString);
      date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return dateString === null
        ? ''
        : date.toLocaleString('en-US', options);
    },
    async openAdditionalDetails (info) {
      this.flyerInfo = {
        flyerId: info.accountId,
        flyerName: info.accountName,
        flyerEmail: info.accountEmail,
        flyerMobile: info.accountMobile,
      };

      this.$modal.show('FlyerShortInfoPopup')
    },
    clearInfo () {
      this.flyerInfo = {}
    },
  },
  components: {
    PlaneCheckbox,
    Pagination,
    FlyerShortInfoPopup,
  },
}
</script>

<template>
  <div class="table-sub" style="height: 93%">
    <Pagination
      :pageCount="pageCount"
      :rowCount="rowCount"
      @pageClick="onPageClick"
    />
    <div class="table-sub-header">
      <div class="table-sub-cell">User</div>
      <div class="table-sub-cell">Created on</div>
      <div class="table-sub-cell">Subscription type</div>
      <div class="table-sub-cell">Status</div>
      <div class="table-sub-cell">Start date</div>
      <div class="table-sub-cell">End date</div>
      <div class="table-sub-cell">Is Apple subscription</div>
    </div>
    <div class="table-sub-body js-customScroll">
      <vue-scroll :ops="ops">
        <div v-for="(item, index) in dataList" :key="index" class="table-sub-row">
          <div
            class="table-sub-row-inner js-acc-hover-item">
            <div class="table-sub-row-header">
              <div class="table-sub-cell">
                <span class="mobile-label">User:</span>
                <a
                  @click="openAdditionalDetails(item)"
                  style="color: darkblue">
                  {{ `${item.accountName} (${item.accountEmail})` }}
                </a>
              </div>
              <div class="table-sub-cell">
                <span class="mobile-label">Created on:</span>
                <span style="word-wrap: break-word">{{ getDisplayDateTime(item.createdOn) }}</span>
              </div>
              <div class="table-sub-cell">
                <span class="mobile-label">Subscription type:</span>
                <span style="word-wrap: break-word">{{ item.planName }}</span>
              </div>
              <div class="table-sub-cell">
                <span class="mobile-label">Status:</span>
                <span style="word-wrap: break-word">{{ item.status }}</span>
              </div>
              <div class="table-sub-cell">
                <span class="mobile-label">Start date:</span>
                <span style="word-wrap: break-word">{{ getDisplayDate(item.startDate) }}</span>
              </div>
              <div class="table-sub-cell">
                <span class="mobile-label">End date:</span>
                <span style="word-wrap: break-word">{{ getDisplayDate(item.endDate) }}</span>
              </div>
              <div class="table-sub-cell">
                <span class="mobile-label">Is Apple subscription:</span>
                <PlaneCheckbox :isChecked="item.isAppleSubscription" :isDisabled="true"/>
              </div>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>
    <FlyerShortInfoPopup :flyerInfo="flyerInfo" @clearInfo="clearInfo"/>
  </div>
</template>
